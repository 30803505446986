import React, { useEffect, useState, useMemo } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { faL } from "@fortawesome/free-solid-svg-icons"
import Select from 'react-select'
import countryList from 'react-select-country-list'

const ContactPage = () => {
  const [parentName, setParentName] = useState(false)
  const [parentEmail, setParentEmail] = useState(false)
  const [parentPhone, setParentPhone] = useState(false)
  const [country, setCountry] = useState(false)
  const options = useMemo(() => countryList().getData(), [])
  const [studentName, setStudentName] = useState(false)
  const [studentAge, setStudentAge] = useState(false)
  const [courseSelected, setCourseSelected] = useState(false)
  const [availibility, setAvailibity] = useState(false)
  const [coursesSelectedRoblox, setCoursesSelectedRoblox] = useState(false)
  const [coursesSelectedPython, setCoursesSelectedPython] = useState(false)
  const [coursesSelectedWebDev, setCoursesSelectedWebDev] = useState(false)

  const checkBoxRoblox = e => {
    setCoursesSelectedRoblox(!coursesSelectedRoblox)
  }
  const checkBoxPython = e => {
    setCoursesSelectedPython(!coursesSelectedPython)
  }
  const checkBoxWebDev = e => {
    setCoursesSelectedWebDev(!coursesSelectedWebDev)
  }
  const registerFormConversion = () => {
    googleFormClick()
    fbEeventHandler()
  }
  const fbEeventHandler = () => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("track", "COMPLETE_REGISTRATION")
      }
    }
  }
  // Google ads conversion
  const googleFormClick = () => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.gtag("event", "conversion", {
          send_to: ["AW-11031846865/h64VCJHBoYQYENG_sowp"],
        })
      }
    }
  }
  const textValidate = e => {
    let value = e.target.value
    let name = e.target.name
    if (value.length > 0) {
      if (name == "parentName") {
        setParentName(true)
      } else if (name == "parentEmail") {
        setParentEmail(true)
      } else if (name == "mobile") {
        setParentPhone(true)
      } else if (name == "country") {
        setCountry(true)
      } else if (name == "studentName") {
        setStudentName(true)
      } else if (name == "studentAge") {
        setStudentAge(true)
      } else if (name == "availibility") {
        setAvailibity(true)
      }
    } else {
      if (name == "parentName") {
        setParentName(false)
      } else if (name == "parentEmail") {
        setParentEmail(false)
      } else if (name == "mobile") {
        setParentPhone(false)
      } else if (name == "country") {
        setCountry(false)
      } else if (name == "studentName") {
        setStudentName(false)
      } else if (name == "studentAge") {
        setStudentAge(false)
      } else if (name == "availibility") {
        setAvailibity(false)
      }
    }
    console.log(e.target.value.length)
  }
  const isFormValid = () => {
    return (
      parentEmail &&
      parentEmail &&
      parentPhone &&
      country &&
      studentName &&
      (coursesSelectedRoblox || coursesSelectedPython || coursesSelectedWebDev)
    )
  }
  const countryChangeHandler = value => {
    setCountry(value)
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("track", "Lead")
      }
    }
  })
  return (
    <Layout activeMenu="contact">
      <SEO title="Contact" />

      <div className="banner_w3lspvt-2">
        {/* <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/" className="font-weight-bold">Home</Link>
        </li>
        <li className="breadcrumb-item" aria-current="page">Contact Us</li>
      </ol> */}
        <div className="icon-effects-w3l">
          <img
            src="../images/shape1.png"
            alt=""
            className="img-fluid shape-wthree shape-w3-one"
          />
          <img
            src="../images/shape2.png"
            alt=""
            className="img-fluid shape-wthree shape-w3-two"
          />
          <img
            src="../images/shape3.png"
            alt=""
            className="img-fluid shape-wthree shape-w3-three"
          />
          <img
            src="../images/shape5.png"
            alt=""
            className="img-fluid shape-wthree shape-w3-four"
          />
          <img
            src="../images/shape4.png"
            alt=""
            className="img-fluid shape-wthree shape-w3-five"
          />
          <img
            src="../images/shape6.png"
            alt=""
            className="img-fluid shape-wthree shape-w3-six"
          />
          <img
            src="../images/shape2.png"
            alt=""
            className="img-fluid shape-wthree shape-w3-seven"
          />
          <img
            src="../images/shape3.png"
            alt=""
            className="img-fluid shape-wthree shape-w3-eight"
          />
          <img
            src="images/shape4.png"
            alt=""
            className="img-fluid shape-wthree shape-w3-nine"
          />
          <img
            src="../images/shape5.png"
            alt=""
            className="img-fluid shape-wthree shape-w3-ten"
          />
        </div>
      </div>

      <div className="contact py-5" id="contact">
        <div className="container pb-xl-5 pb-lg-3">
          <div className="row">
            <div className="col-lg-6">
              <img src="../images/guy2.gif" alt="image" className="img-fluid" />
            </div>
            <div className="col-lg-6 mt-lg-0 mt-5">
              <div className="contact-top1">
                <form
                  action="https://submit-form.com/BnoUlZ9TU"
                  method="POST"
                  className="contact-wthree-do"
                >
                  <input
                    type="hidden"
                    name="_redirect"
                    value="https://www.codingchamps.ca/thankyou"
                  />
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <label>Parent's Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="parentName"
                          required={true}
                          onChange={e => textValidate(e)}
                        />
                      </div>
                      <div className="col-md-6 mt-md-0 mt-4">
                        <label>Email</label>
                        <input
                          className="form-control"
                          type="email"
                          name="parentEmail"
                          required={true}
                          onChange={e => textValidate(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <label>Mobile</label>
                        <input
                          className="form-control"
                          type="text"
                          name="mobile"
                          required={true}
                          onChange={e => textValidate(e)}
                        />
                      </div>
                      <div className="col-md-6 mt-md-0 mt-4">
                        <label>Country</label>
                        {/* <input
                          className="form-control"
                          type="text"
                          name="country"
                          required={true}
                          onChange={e => textValidate(e)}
                        /> */}
                        <Select name="country" options={options} value={country} onChange={countryChangeHandler} />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <label>Student's Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="studentName"
                          required={true}
                          onChange={e => textValidate(e)}
                        />
                      </div>
                      <div className="col-md-6 mt-md-0 mt-4">
                        <label>Student's Age</label>
                        <input
                          className="form-control"
                          type="number"
                          name="studentAge"
                          required={true}
                          onChange={e => textValidate(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-12">
                        <label>Select Course:</label>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="roblox"
                            name="roblox"
                            id="roblox"
                            onClick={e => checkBoxRoblox(e)}
                          />
                          <label class="form-check-label" for="roblox">
                            Roblox Game Development
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="python"
                            name="python"
                            id="python"
                            onClick={e => checkBoxPython(e)}
                          />
                          <label class="form-check-label" for="python">
                            Python
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="webDev"
                            name="webDev"
                            id="webDev"
                            onClick={e => checkBoxWebDev(e)}
                          />
                          <label class="form-check-label" for="webDev">
                            Web Development
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-12">
                        <label>Availibility for classes</label>
                        <textarea
                          placeholder="Please enter the availibity of your child for classes."
                          name="availibility"
                          className="form-control"
                          onChange={e => textValidate(e)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-12">
                        <label>Additional Information</label>
                        <textarea
                          placeholder="Any additional information of your child that would help us in teaching him/her"
                          name="additionalInfo"
                          className="form-control"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-12">
                      <button
                        type="submit"
                        className="btn btn-cont-w3 btn-block mt-4"
                        // onClick={registerFormConversion}
                        disabled={
                          (coursesSelectedRoblox ||
                            coursesSelectedPython ||
                            coursesSelectedWebDev) &&
                          parentName &&
                          parentEmail &&
                          country &&
                          parentPhone &&
                          studentName &&
                          studentAge &&
                          availibility
                            ? false
                            : true
                        }
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div class="w3l-map p-4 map-background"></div> */}
    </Layout>
  )
}

export default ContactPage
